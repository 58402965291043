$('.cb-manufacturers .manufacturers').slick({
    arrows: true,
    speed: 300,
    loop: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 6,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 320,
            settings: {
                slidesToShow: 1,
            }
        }
    ],
});