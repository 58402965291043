import $ from 'jquery';


export default class SlickSlider {
    init() {
        $('[data-slick]').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                //return;
            }

            self.slick({
                prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><img src='/themes/bogaro/assets/img/slick-arrow-left.svg' /></button>",
                nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><img src='/themes/bogaro/assets/img/slick-arrow-left.svg' /></button>",
                });
        });
    }

}
